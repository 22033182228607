import { Button, Icon, Layout, Menu, notification } from "antd";
import * as React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { UpdatePassword } from "../pages/settings/components/UpdatePassword";
import { TestEmail } from "../pages/settings/components/TestEmail";
import { useAxios } from "../util/useAxios";
import { RatesReminder } from "./RatesReminder";
import { SettingsContext } from "../AppRouter";

const { Header, Sider, Content } = Layout;

const FullScreenLayout = styled(Layout)`
  height: 100vh;
`;

const StyledMenu = styled(Menu)`
  .ant-menu-item-group-title {
    font-size: 1rem;
    font-weight: bold;
    color: #545556;
  }
`;

const PageTitle = styled.h1`
  a {
    color: white;
  }
`;

const CustomHeader = styled(Header)`
  padding: 0 20px;
  display: flex;
  line-height: 64px;
  justify-content: space-between;
  align-items: center;
  h1 {
    color: white;
    margin: 0;
    display: inline;
  }
`;

export const AdminLayout = withRouter(({ children, location }) => {
  const [{ loading }, logout] = useAxios({
    url: "/api/admin/access/logout",
    method: "POST",
    manual: true
  });

  const onLogout = async () => {
    const { response, error } = await logout();
    if (error === undefined && response.status === 200) {
      window.location.reload();
    } else {
      notification.error({
        message: "Logout nicht erfolgreich.",
        description:
          "Probieren Sie es erneut oder löschen Sie manuell ihre Browser Cookies."
      });
    }
  };

  const {
    settings: { hotelName }
  } = useContext(SettingsContext);

  return (
    <FullScreenLayout>
      <CustomHeader>
        <PageTitle>
          <Link to="/">Administration {hotelName}</Link>
        </PageTitle>
        <Button
          loading={loading}
          type="danger"
          icon="logout"
          onClick={onLogout}
        >
          <span>Logout</span>
        </Button>
      </CustomHeader>
      <Layout>
        <Sider width={256} theme="light">
          <StyledMenu
            style={{ width: 256 }}
            defaultSelectedKeys={["/"]}
            selectedKeys={[location.pathname]}
            mode="inline"
          >
            <Menu.Item key="/">
              <Link to="/">
                <span>
                  <Icon type="bulb" />
                  Startseite
                </span>
              </Link>
            </Menu.Item>
            <Menu.ItemGroup
              key="group1"
              title="Verwaltung"
              renderMenuItem={(item: any) => item}
            >
              <Menu.Item key="/room-types">
                <Link to="/room-types">
                  <Icon type="border" /> <span>Zimmer</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/rates">
                <RatesReminder>
                  <Link to="/rates">
                    <Icon type="dollar" /> <span>Pauschalen</span>
                  </Link>
                </RatesReminder>
              </Menu.Item>
              <Menu.Item key="/arrangements">
                <Link to="/arrangements">
                  <Icon type="star" /> <span>Arrangements</span>
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup
              key="group2"
              title="System"
              renderMenuItem={(item: any) => item}
            >
              <Menu.Item key="/settings">
                <Link to="/settings">
                  <Icon type="setting" />
                  <span>Einstellungen</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="password-reset">
                <UpdatePassword />
              </Menu.Item>
              <Menu.Item key="test-email">
                <TestEmail />
              </Menu.Item>
            </Menu.ItemGroup>
          </StyledMenu>
        </Sider>
        <Content>{children}</Content>
      </Layout>
    </FullScreenLayout>
  );
});
