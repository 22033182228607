import styled from "styled-components";
import { Card } from "antd";

export const DashboardTile = styled(Card)`
  .ant-card-body {
    padding: 0.75rem;
  }
  margin-bottom: 2rem;
`;

export const TileHeading = styled.h3`
  font-size: 1.2rem;
  padding: 0.5rem 0.75rem 0 0.75rem;
  margin: 0;
`;

export const TileContainer = styled.div`
  padding: 0.5rem;
`;
