import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Alert, Button, Icon, Modal, notification } from "antd";
import { IArrangement } from "@accommod/common";
import { Formik } from "formik";
import {
  ArrangementForm,
  arrangementValidationSchema,
  FormArrangement
} from "./components/ArrangementForm";
import moment from "moment";
import { FormBody } from "./CreateArrangement";
import { useAxios } from "../../util/useAxios";

type EditArrangementProps = {
  arrangement: IArrangement;
  onEdit: () => void;
};

const EditIcon = styled(Icon)`
  font-size: 1.3rem;
  cursor: pointer;
`;

export const EditArrangement: React.FC<EditArrangementProps> = ({
  onEdit,
  arrangement
}) => {
  const [visible, setVisible] = useState();
  const toggle = () => setVisible(!visible);
  const [{ loading, error }, editArrangement] = useAxios({
    url: "/api/admin/arrangements",
    method: "PUT",
    manual: true
  });
  const {
    timeFrameStart,
    timeFrameEnd,
    priceDZ,
    priceEZ,
    nights,
    id,
    imageFileName,
    isActive,
    orderNumber,
    pdfFileName,
    text,
    title
  } = arrangement;
  const initialValues: FormArrangement = {
    imageFileName,
    isActive,
    pdfFileName,
    text,
    title,
    priceDZ: priceDZ || 0,
    priceEZ: priceEZ || 0,
    nights: nights || 0,
    timeFrame: [moment(timeFrameStart), moment(timeFrameEnd)]
  };
  const handleSubmit = async ({
    timeFrame,
    priceDZ,
    priceEZ,
    nights,
    ...values
  }: FormArrangement) => {
    const transformed = {
      id,
      ...values,
      priceDZ,
      priceEZ,
      nights,
      timeFrameStart: timeFrame![0],
      timeFrameEnd: timeFrame![1]
    };
    const { error, response } = await editArrangement({ data: transformed });
    if (!error && response.status === 200) {
      onEdit();
      notification.success({
        message: "Arrangement wurde erfolgreich gespeichert."
      });
      toggle();
    }
  };
  return (
    <>
      <EditIcon type="edit" onClick={toggle} title="Arrangement editieren..." />
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={arrangementValidationSchema}
      >
        {formik => {
          const { submitForm } = formik;
          return (
            <Modal
              visible={visible}
              onCancel={toggle}
              width={680}
              title="Arrangement editieren"
              footer={
                <>
                  <Button onClick={toggle}>Abbrechen</Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={submitForm}
                    loading={loading}
                  >
                    Arrangement speichern
                  </Button>
                </>
              }
            >
              <FormBody>
                <ArrangementForm formik={formik} />
                {error && error.response && error.response.data && (
                  <Alert
                    type="error"
                    message="Arrangement konnte nicht bearbeitet werden."
                    description={`Interne Fehlermeldung: ${
                      error.response.data
                    }`}
                  />
                )}
              </FormBody>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
