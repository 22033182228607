import styled from "styled-components";
import { Row } from "antd";

export const MainCardHead = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PreviewWrapper = styled.div`
  padding-left: 1.5rem;
`;

export const FlexCardRow = styled(Row)`
  .ant-col {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .ant-card {
      height: 100%;
    }
  }
  @media (max-width: 991px) {
    .ant-col {
      margin-bottom: 1.5rem;
    }
  }
`;

export const FieldRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  .ant-form-item {
    margin-right: 1rem;
  }
  .ant-form-item-label {
    flex-direction: row;
  }
`;

export const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1rem 0;
  button {
    margin-left: 1rem;
  }
`;

export const Label = styled.span`
  display: block;
  line-height: initial;
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 0.5rem;
`;

export const FlexGrowWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .ant-card {
    flex-grow: 1;
  }
`;
