import { Spin } from "antd";
import React from "react";
import styled from "styled-components";

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TakeSpaceSpinner: React.FC<{ loading?: boolean }> = ({
  loading = true
}) => {
  if (loading) {
    return (
      <Overlay>
        <Spin tip="Loading..." size="large" />
      </Overlay>
    );
  }
  return null;
};
