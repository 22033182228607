import * as React from "react";
import { useState } from "react";
import { UploadChangeParam } from "antd/lib/upload";
import { Icon, notification } from "antd";
import styled from "styled-components";
import { Upload } from "../../../components/Upload";

const Label = styled.span`
  text-align: right;
  display: block;
  line-height: initial;
  font-weight: bold;
  margin-top: -1rem;
  padding-right: 0.5rem;
`;

export const RoomPreviewUpload: React.FC<{
  onChange: (value: string) => void;
  value?: string;
}> = ({ value, onChange }) => {
  const [loading, setLoading] = useState(false);

  const handleChange = (info: UploadChangeParam) => {
    switch (info.file.status) {
      case "uploading":
        if (!loading) {
          setLoading(true);
        }
        break;
      case "error":
        notification.error({
          message: "Upload fehlgeschlagen",
          description: `Es ist ein Fehler beim Hochladen aufgetreten.${
            info.file.response && info.file.response.length
              ? ` Interne Fehlermeldung: ${info.file.response}`
              : ""
          }`
        });
        setLoading(false);
        break;
      case "done":
        setLoading(false);
        onChange(info.file.response);
        break;
    }
  };
  const handlePreview = console.log;

  return (
    <div>
      <Upload
        accept="image/*"
        multiple={false}
        listType="picture-card"
        action="/api/admin/room-types/upload/preview"
        showUploadList={false}
        onChange={handleChange}
      >
        {loading ? (
          <Icon type="loading" style={{ fontSize: "2rem" }} />
        ) : value ? (
          <img
            src={`/e-static/room-previews/${value}`}
            alt="Zimmer Bild"
            style={{ width: "100%" }}
          />
        ) : (
          <div>
            <div className="ant-upload-text">Vorschau hochladen</div>
          </div>
        )}
      </Upload>
      {value && <Label>Vorschaubild - klein</Label>}
    </div>
  );
};
