import * as React from "react";
import { RoomTypeList } from "./components/RoomTypeList";
import styled from "styled-components";
import { useAxios } from "../../../util/useAxios";
import { Button } from "antd";
import { Link } from "react-router-dom";

const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0 0.75rem 0.5rem 0.75rem;
`;

export const ShowRoomTypes = () => {
  const [{ loading, error, data }, reFetch] = useAxios({
    url: "/api/admin/room-types"
  });

  return (
    <>
      <ButtonBar>
        <Link to="/room-types/new">
          <Button icon="plus">Neuen Zimmer-Typ anlegen</Button>
        </Link>
      </ButtonBar>
      <RoomTypeList
        loading={loading}
        error={error}
        roomTypes={data}
        reFetch={reFetch}
      />
    </>
  );
};
