import { useHistory, useParams } from "react-router-dom";
import { useAxios } from "../../../util/useAxios";
import { Alert, Card, Col, notification } from "antd";
import { IRoomType, IRoomTypeCreate } from "@accommod/common";
import { RoomTypeForm } from "../components/RoomTypeForm";
import * as React from "react";
import { Container } from "../create-room-type";
import { FlexCardRow } from "../components/styled-components";

export const EditRoomType = () => {
  const { push } = useHistory();
  const { id } = useParams();
  const [{ loading: loadingSave }, updateRoomType] = useAxios({
    url: "/api/admin/room-types",
    method: "PUT",
    manual: true,
    onError: error => {
      notification.error({
        message: "Zimmer-Typ konnte nicht gespeichert werden.",
        description: error.response
          ? `Grund: ${error.response.data}`
          : undefined
      });
    },
    onSuccess: () => {
      notification.success({
        message: "Zimmer-Typ wurde erfolgreich gespeichert."
      });
      push("/room-types/");
    }
  });
  const [{ loading, error, data }] = useAxios<IRoomType>(
    `/api/admin/room-types/${id}`
  );

  if (loading) {
    return (
      <Container>
        <FlexCardRow type="flex" gutter={32}>
          <Col lg={{ span: 12 }} sm={{ span: 24 }}>
            <Card loading style={{ height: "24rem" }} />
          </Col>
          <Col lg={{ span: 12 }} sm={{ span: 24 }}>
            <Card loading style={{ height: "24rem" }} />
          </Col>
        </FlexCardRow>
      </Container>
    );
  }
  if (error || !data) {
    return (
      <Container>
        <Alert
          type="error"
          message="Zimmer-Typ konnte nicht gefunden werden."
          description={
            error && error.response
              ? error.response.status === 404
                ? "Eventuell wurde der Zimmer-Typ in der Zwischenzeit gelöscht."
                : `Fehlermeldung: ${error.response.data}`
              : undefined
          }
        />
      </Container>
    );
  }

  const handleSubmit = (roomType: Partial<IRoomTypeCreate>) => {
    updateRoomType({ data: {...roomType, id: data.id} });
  };
  return (
    <Container>
      <RoomTypeForm
        id={data.id}
        loading={loadingSave}
        onSubmit={handleSubmit}
        initialValues={{
          images: data.images.map(({ fileName, orderNumber }) => ({
            fileName,
            orderNumber
          })),
          beds: data.beds,
          name: data.name,
          description: data.description,
          previewSmallFileName: data.previewSmallFileName,
          linkFilter: data.linkFilter,
          priceWeekend: data.priceWeekend,
          priceWorkDay: data.priceWorkDay,
          sizeSQ: data.sizeSQ
        }}
      />
    </Container>
  );
};
