import React, { FunctionComponent } from "react";
import { ConfigProvider } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import deDe from "antd/es/locale/de_DE";
import "moment/locale/de";
import { AppRouter } from "./AppRouter";

moment.locale("de");

export const App: FunctionComponent<{}> = () => (
  <ConfigProvider locale={deDe}>
    <AppRouter />
  </ConfigProvider>
);
