import * as React from "react";
import { Alert, Empty } from "antd";
import styled from "styled-components";
import { AxiosError } from "axios";
import { IRoomType } from "@accommod/common";
import {
  RoomCardContainer,
  RoomTypeCard,
  StyledRoomCard
} from "./RoomTypeCard";
import { Center } from "../../../../components/Center";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const RoomTypeList: React.FC<{
  loading: boolean;
  error?: AxiosError;
  roomTypes: IRoomType[];
  reFetch: () => void;
}> = ({ reFetch, roomTypes, error, loading }) => {
  if (loading) {
    return (
      <Container>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map(
          i => (
            <RoomCardContainer key={i}>
              <StyledRoomCard loading style={{ minHeight: 320 }} />
            </RoomCardContainer>
          )
        )}
      </Container>
    );
  }
  if (error) {
    return (
      <Alert
        type="error"
        message="Es konnten keine Zimmer geladen werden."
        description={error.response ? error.response.data : "Internal error"}
      />
    );
  }
  if (!roomTypes || !roomTypes.length) {
    return (
      <Center>
        <Empty description="Es sind noch keine Zimmer angelegt worden." />
      </Center>
    );
  }
  return (
    <Container>
      {roomTypes.map((roomType, index) => (
        <RoomTypeCard
          reFetch={reFetch}
          key={roomType.id}
          roomType={roomType}
          index={index}
          itemCount={roomTypes.length}
        />
      ))}
    </Container>
  );
};
