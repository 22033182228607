import * as React from "react";
import { GlobalPrice, GlobalPriceKey } from "@accommod/common";
import { Form, Formik } from "formik";
import { FormItem, InputGroup } from "./FormItem";
import { InputNumber } from "./InptutNumber";
import { Button, Col, notification, Row } from "antd";
import { useAxios } from "../util/useAxios";
import * as Yup from "yup";

export const GlobalPricesForm: React.FC<{ globalPrices: GlobalPrice[] }> = ({
  globalPrices
}) => {
  const initialValues = Object.values(GlobalPriceKey).reduce((acc, key) => {
    return {
      ...acc,
      [key]: globalPrices.find(gp => gp.key === key)?.price || undefined
    };
  }, {});
  const [{ loading }, request] = useAxios({
    url: "/api/admin/global-prices",
    method: "POST",
    manual: true,
    onError: error => {
      notification.error({
        message: "Beim Speichern ist ein Fehler aufgetreten.",
        description:
          error && error.response
            ? `Interne Fehlermeldung: ${error.response.data}`
            : undefined
      });
    },
    onSuccess: () => {
      notification.success({ message: "Die Pauschalen wurden gespeichert." });
    }
  });

  const handleSubmit = (values: any) => {
    request({
      data: {
        globalPrices: Object.keys(values).map(key => ({
          key,
          value: values[key]
        }))
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object(
        Object.keys(initialValues).reduce(
          (acc, key) => ({
            ...acc,
            [key]: Yup.number()
              .typeError("Bitte geben Sie einen Preis ein.")
              .required("Bitte geben Sie einen Preis ein.")
          }),
          {}
        )
      )}
    >
      {({ submitForm, dirty }) => {
        return (
          <Form>
            <Row>
              <Col md={24} lg={12}>
                <FormItem
                  label="Aufpreis für extra Personen"
                  name={GlobalPriceKey.ExtraPersonRate}
                >
                  <InputGroup compact>
                    <InputNumber
                      name={GlobalPriceKey.ExtraPersonRate}
                      style={{ width: 200, maxWidth: "100%" }}
                      min={0}
                      precision={2}
                      decimalSeparator=","
                    />
                    <Button disabled>€</Button>
                  </InputGroup>
                </FormItem>
              </Col>
              <Col md={24} lg={12}>
                <FormItem
                  label="Aufpreis für Kinder"
                  name={GlobalPriceKey.ExtraChildRate}
                >
                  <InputGroup compact>
                    <InputNumber
                      name={GlobalPriceKey.ExtraChildRate}
                      style={{ width: 200, maxWidth: "100%" }}
                      min={0}
                      precision={2}
                      decimalSeparator=","
                    />
                    <Button disabled>€</Button>
                  </InputGroup>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={12}>
                <FormItem
                  label="Tagungspauschale (voll)"
                  name={GlobalPriceKey.FullConferenceRate}
                >
                  <InputGroup compact>
                    <InputNumber
                      name={GlobalPriceKey.FullConferenceRate}
                      style={{ width: 200, maxWidth: "100%" }}
                      min={0}
                      precision={2}
                      decimalSeparator=","
                    />
                    <Button disabled>€</Button>
                  </InputGroup>
                </FormItem>
              </Col>
              <Col md={24} lg={12}>
                <FormItem
                  label="Tagungspauschale (halbtags)"
                  name={GlobalPriceKey.HalfConferenceRate}
                >
                  <InputGroup compact>
                    <InputNumber
                      name={GlobalPriceKey.HalfConferenceRate}
                      style={{ width: 200, maxWidth: "100%" }}
                      min={0}
                      precision={2}
                      decimalSeparator=","
                    />
                    <Button disabled>€</Button>
                  </InputGroup>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={12}>
                <FormItem
                  label="Pauschale für Kaffeepause"
                  name={GlobalPriceKey.HalfConferenceRate}
                >
                  <InputGroup compact>
                    <InputNumber
                      name={GlobalPriceKey.CoffeeConferenceRate}
                      style={{ width: 200, maxWidth: "100%" }}
                      min={0}
                      precision={2}
                      decimalSeparator=","
                    />
                    <Button disabled>€</Button>
                  </InputGroup>
                </FormItem>
              </Col>
              <Col md={24} lg={12}>
                <FormItem
                  label="Pauschale für Abendessen"
                  name={GlobalPriceKey.DinnerConferenceRate}
                >
                  <InputGroup compact>
                    <InputNumber
                      name={GlobalPriceKey.DinnerConferenceRate}
                      style={{ width: 200, maxWidth: "100%" }}
                      min={0}
                      precision={2}
                      decimalSeparator=","
                    />
                    <Button disabled>€</Button>
                  </InputGroup>
                </FormItem>
              </Col>
            </Row>
            <Button
              onClick={submitForm}
              loading={loading}
              type="primary"
              disabled={!dirty}
            >
              Speichern
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
