import * as React from "react";
import { useContext, useState } from "react";
import { Button, Icon, Input, Modal, notification } from "antd";
import { SettingsContext } from "../../../AppRouter";
import { useAxios } from "../../../util/useAxios";

export const TestEmail = () => {
  const [modalVisible, setVisible] = useState(false);
  const toggle = () => setVisible(!modalVisible);

  const { settings } = useContext(SettingsContext);
  const [{ loading }, sendTestEmail] = useAxios({
    url: "/api/admin/system-config/test-email",
    method: "POST",
    manual: true
  });
  const handleSendEmail = async () => {
    const { error, response } = await sendTestEmail();
    if (!error && response.status === 200) {
      notification.success({
        message: "Email wurde versendet.",
        description: `Das System hat eine Email an ${settings.bookingEmail} zum Test versendet.`
      });
      toggle();
    } else {
      notification.error({
        message: "Email konnte nicht versendet werden.",
        description:
          error && error.response
            ? `Interne Fehlermeldung: ${error.response.data}`
            : undefined
      });
    }
  };
  return (
    <>
      <div onClick={toggle}>
        <Icon type="mail" /> Emailversand testen
      </div>
      <Modal
        visible={modalVisible}
        onCancel={toggle}
        title="Emailversand testen"
        footer={
          <>
            <Button onClick={toggle}>Abbrechen</Button>
            <Button onClick={handleSendEmail} type="primary" loading={loading}>
              Email versenden
            </Button>
          </>
        }
      >
        <p>
          Um zu testen, ob das System Emails an die hinterlegte Buchungsemail
          versenden kann, drücken sie{" "}
          <b>
            <i>Email versenden</i>
          </b>
          .
        </p>
        <br />
        <Input
          addonBefore="Email:"
          readOnly
          value={settings ? settings.bookingEmail : "..."}
        />
      </Modal>
    </>
  );
};
