import * as React from "react";
import { useAxios } from "../../../util/useAxios";
import { Alert } from "antd";
import { PriceOverrideCardContent } from "./PriceOverrideCardContent";
import styled from "styled-components";
import { DashboardTile, TileHeading } from "../components/DashboardTile";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const PriceOverrideTile: React.FC = () => {
  const [{ loading, error, data }, reFetch] = useAxios({
    url: "/api/admin/room-types"
  });

  return (
    <DashboardTile loading={loading}>
      <TileHeading>Zimmerpreise</TileHeading>
      {error || !data ? (
        <Alert
          type="error"
          message="Zimmer-Typen konnten nicht geladen werden."
          description={
            error && error.response
              ? `Fehlermeldung: ${error.response.data}`
              : undefined
          }
        />
      ) : (
        <Container>
          <PriceOverrideCardContent reFetch={reFetch} roomTypes={data} />
        </Container>
      )}
    </DashboardTile>
  );
};
