"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BedNotationShortMap = {
  doubleBeds: "db",
  singleBeds: "sb",
  childrenBeds: "cb",
  singleCouch: "sc",
  doubleCouch: "dc",
  doubleBunkBeds: "dbb",
  tripleBunkBeds: "tbb"
};
exports.BedNotationCapacityMap = {
  doubleBeds: 2,
  singleBeds: 1,
  childrenBeds: 1,
  singleCouch: 1,
  doubleCouch: 2,
  doubleBunkBeds: 2,
  tripleBunkBeds: 3
};