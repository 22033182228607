"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var SystemSettingKey;

(function (SystemSettingKey) {
  SystemSettingKey["Password"] = "systemPassword";
  SystemSettingKey["BookingEmail"] = "bookingEmail";
  SystemSettingKey["HotelName"] = "hotelName";
})(SystemSettingKey = exports.SystemSettingKey || (exports.SystemSettingKey = {}));