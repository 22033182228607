import * as React from "react";
import { AdminLayout } from "../../components/AdminLayout";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";
import { ShowRoomTypes } from "./show-room-types";
import { CreateRoomType } from "./create-room-type";
import { EditRoomType } from "./edit-room-type";

const ContentWrapper = styled.div`
  width: 100%;
  padding: 0.75rem;
`;
const Heading = styled.h2`
  margin: 0.75rem;
`;

export const RoomTypesPage = () => (
  <AdminLayout>
    <ContentWrapper>
      <Heading>Zimmer</Heading>
      <Switch>
        <Route path="/room-types/new" component={CreateRoomType} />
        <Route path="/room-types/:id" component={EditRoomType} />
        <Route path="*" component={ShowRoomTypes} />
      </Switch>
    </ContentWrapper>
  </AdminLayout>
);
