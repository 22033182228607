import * as React from "react";
import { FormikProps } from "formik";
import { Form } from "antd";
import { FormItem } from "../../../components/FormItem";
import { Input } from "formik-antd";
import { UpdatePasswordFormValues } from "./UpdatePassword";
import * as Yup from "yup";

type UpdatePasswordProps = {
  formik: FormikProps<UpdatePasswordFormValues>;
};

export const updatePasswordValidationSchema = Yup.object({
  currentPassword: Yup.string().required(
    "Bitte geben Sie das aktuelle Passwort ein."
  ),
  newPassword: Yup.string()
    .required("Bitte geben Sie ein neues Passwort ein.")
    .min(8, "Ihr Passwort sollte mindestens 8 Zeichen lang sein.")
    .max(255, "Ihr Passwort sollte weniger als 255 Zeichen lang sein."),
  newPasswordRepeat: Yup.string()
    .required("Bitte wiederholen Sie das neue Passwort.")
    .oneOf(
      [Yup.ref("newPassword"), null],
      "Die Passwörter stimmen nicht überein."
    )
});

export const UpdatePasswordForm: React.FC<UpdatePasswordProps> = ({
  formik: { errors, touched }
}) => {
  return (
    <Form>
      <FormItem label="Aktuelles Passwort" name="currentPassword">
        <Input name="currentPassword" type="password" />
      </FormItem>
      <FormItem label="Neues Passwort" name="newPassword">
        <Input name="newPassword" type="password" />
      </FormItem>
      <FormItem label="Neues Passwort bestätigen" name="newPasswordRepeat">
        <Input name="newPasswordRepeat" type="password" />
      </FormItem>
    </Form>
  );
};
