import * as React from "react";
import { Formik } from "formik";
import { FormItem } from "../../../components/FormItem";
import { Form, Input } from "formik-antd";
import { Alert, Button, Icon, notification, Tooltip } from "antd";
import { FormButtonBar } from "../../../components/FormButtonBar";
import { useAxios } from "../../../util/useAxios";

type SettingsFormProps = {
  settings: Settings;
  onUpdate: () => void;
};

type Settings = {
  bookingEmail: string;
};

export const SettingsForm: React.FC<SettingsFormProps> = ({
  settings: { bookingEmail },
  onUpdate
}) => {
  const [{ loading, error }, saveSettings] = useAxios({
    url: "/api/admin/system-config/settings",
    method: "POST",
    manual: true
  });
  const handleSubmit = async (values: Settings) => {
    const { error, response } = await saveSettings({ data: values });
    if (!error && response.status === 200) {
      notification.success({
        message: "Die Einstellungen wurden gespeichert."
      });
      onUpdate();
    }
  };
  return (
    <Formik
      initialValues={{
        bookingEmail
      }}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <FormItem label="Buchungsemail" name="bookingEmail">
            <Input
              name="bookingEmail"
              addonAfter={
                <Tooltip title="An diese Email Adresse werden alle Buchungsanfragen von Kunden geschickt.">
                  <Icon type="info-circle" />
                </Tooltip>
              }
            />
          </FormItem>
          {error && error.response && (
            <Alert
              style={{ marginBottom: "1rem" }}
              type="error"
              message="Einstellungen konnten nicht gespeichert werden."
              description={
                error.response.data ? error.response.data : undefined
              }
            />
          )}
          <FormButtonBar>
            <Button htmlType="submit" type="primary" loading={loading}>
              Einstellungen speichern
            </Button>
          </FormButtonBar>
        </Form>
      )}
    </Formik>
  );
};
