import { useEffect, useReducer } from "react";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

enum ActionType {
  REQUEST_START = "REQUEST_START",
  REQUEST_END = "REQUEST_END"
}

type State<T> = {
  loading: boolean;
  error?: AxiosError;
  response?: AxiosResponse;
  data?: T;
};
type Action = { type: ActionType; error?: boolean; payload?: any };

function reducer(state: State<any>, action: Action): State<any> {
  switch (action.type) {
    case ActionType.REQUEST_START:
      return {
        ...state,
        loading: true
      };
    case ActionType.REQUEST_END:
      return {
        ...state,
        loading: false,
        ...(action.error
          ? { error: action.payload }
          : {
              error: undefined,
              response: action.payload,
              data: action.payload.data
            })
      };
    default:
      return state;
  }
}

type Result = { error?: AxiosError; response: AxiosResponse };

type Config<T = any> = Partial<AxiosRequestConfig> & {
  manual?: boolean;
  onSuccess?: (response: AxiosResponse<T>) => void;
  onError?: (error: AxiosError) => void;
  transform?: (data: any) => T;
};

async function request<T = any>(
  { onError, onSuccess, ...config }: Config<T>,
  dispatch: (action: Action) => any
): Promise<Result> {
  try {
    dispatch({ type: ActionType.REQUEST_START });
    const response = await axios(config);
    if (typeof config.transform === "function") {
      response.data = config.transform(response.data);
    }
    dispatch({ type: ActionType.REQUEST_END, payload: response });
    if (onSuccess) {
      onSuccess(response);
    }
    return {
      response,
      error: undefined
    };
  } catch (error) {
    dispatch({ type: ActionType.REQUEST_END, payload: error, error: true });
    if (onError) {
      onError(error);
    }
    return {
      response: undefined,
      error
    } as any;
  }
}

export function useAxios<T = any>(
  config: Config | string
): [
  State<T>,
  (configOverride?: Partial<AxiosRequestConfig>) => Promise<Result>
] {
  const combinedConfig =
    typeof config === "string"
      ? {
          url: config,
          manual: false
        }
      : config;

  const [state, dispatch] = useReducer(reducer, {
    loading: !combinedConfig.manual
  });

  useEffect(() => {
    if (!combinedConfig.manual) {
      request<T>(combinedConfig, dispatch);
    }
  }, [JSON.stringify(config)]);

  return [
    state,
    (configOverride?) =>
      request<T>({ ...combinedConfig, ...configOverride }, dispatch)
  ];
}
