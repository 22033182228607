"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Bookable;

(function (Bookable) {
  Bookable["Room"] = "room";
  Bookable["Arrangement"] = "arrangement";
  Bookable["ConferenceRoom"] = "conference-room";
})(Bookable = exports.Bookable || (exports.Bookable = {}));

var ConferenceBookingRate;

(function (ConferenceBookingRate) {
  ConferenceBookingRate["HalfTime"] = "half-time";
  ConferenceBookingRate["FullTime"] = "full-time";
})(ConferenceBookingRate = exports.ConferenceBookingRate || (exports.ConferenceBookingRate = {}));