import * as React from "react";
import styled from "styled-components";
import { Icon, notification } from "antd";
import { useAxios } from "../../util/useAxios";

const MoveIcon = styled(Icon)`
  font-size: 1.3rem;
  cursor: pointer;
`;

export const MoveArrangement: React.FC<{
  reFetch: () => void;
  orderNumber: number;
  direction: "up" | "down";
}> = ({ reFetch, direction, orderNumber }) => {
  const [{ loading }, request] = useAxios({
    url: `/api/admin/arrangements/move`,
    manual: true,
    method: "POST",
    onSuccess: () => {
      reFetch();
    },
    onError: () => {
      notification.error({
        message: "Beim Verschieben ist ein Fehler aufgetreten."
      });
    }
  });
  return (
    <>
      <MoveIcon
        title={
          direction === "up" ? "Nach oben schieben" : "Nach unten schieben"
        }
        type={
          loading ? "loading" : direction === "up" ? "arrow-up" : "arrow-down"
        }
        onClick={() => request({ data: { orderNumber, direction } })}
      />
    </>
  );
};
