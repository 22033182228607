import * as React from "react";
import { useState } from "react";
import { Button, Modal } from "antd";
import styled from "styled-components";

const PreviewImage = styled.img`
  max-width: 6rem;
  cursor: pointer;
`;

const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    max-width: 100%;
  }
`;

type ImagePreviewModalProps = {
  imgSrc: string;
  alt: string;
};

export const ImagePreviewModal: React.FC<ImagePreviewModalProps> = ({
  imgSrc,
  alt
}) => {
  const [modalVisible, setVisible] = useState(false);
  const toggle = () => setVisible(!modalVisible);
  return (
    <>
      <Modal
        title="Arrangement Bild: Großansicht"
        visible={modalVisible}
        width={900}
        onCancel={toggle}
        footer={<Button onClick={toggle}>Vorschau schließen</Button>}
      >
        <Center>
          <img src={imgSrc} alt={alt} />
        </Center>
      </Modal>
      <PreviewImage onClick={toggle} src={imgSrc} alt={alt} />
    </>
  );
};
