import * as React from "react";
import { Field, FieldProps, Formik } from "formik";
import { Input } from "formik-antd";
import * as Yup from "yup";
import { Button, Card, Col } from "antd";
import { IRoomTypeCreate } from "@accommod/common";
import { RoomPictureWall } from "./RoomPictureWall";
import styled from "styled-components";
import { RoomPreviewUpload } from "./RoomPreviewUpload";
import { DeleteRoomType } from "./DeleteRoomType";
import { FormItem, InputGroup } from "../../../components/FormItem";
import { InputNumber } from "../../../components/InptutNumber";
import {
  ButtonBar,
  FieldRow,
  FlexCardRow,
  FlexGrowWithLabel,
  Label,
  MainCardHead,
  PreviewWrapper
} from "./styled-components";
import { Link } from "react-router-dom";

const { TextArea } = Input;

export const roomTypeValidationSchema = Yup.object({
  name: Yup.string()
    .required(" ")
    .max(100, "Hier sind maximal ${max} Zeichen erlaubt."),
  linkFilter: Yup.string().nullable(),
  description: Yup.string()
    .required(" ")
    .max(5000, "Hier sind maximal ${max} Zeichen erlaubt."),
  priceWeekend: Yup.number()
    .typeError("Diese Eingabe ist kein valider Preis.")
    .required(" "),
  priceWorkDay: Yup.number()
    .required(" ")
    .typeError("Diese Eingabe ist kein valider Preis."),
  sizeSQ: Yup.number()
    .required(" ")
    .typeError("Diese Eingabe ist kein valider Preis."),
  previewSmallFileName: Yup.string().required(" "),
  images: Yup.array(
    Yup.object({
      orderNumber: Yup.number(),
      fileName: Yup.string()
    })
  )
    .min(1, "Bitte laden Sie mindestens ein Bild des Zimmers hoch.")
    .required("Bitte laden Sie mindestens ein Bild des Zimmer-Typs hoch.")
});

const NameInput = styled(Input)`
  font-size: 1.6rem;
  height: 3rem;
`;
const Explanation = styled.p`
  font-size: 0.8rem;
  line-height: normal;
`;

export const RoomTypeForm: React.FC<{
  loading: boolean;
  id?: string;
  initialValues: Partial<IRoomTypeCreate>;
  onSubmit: (values: Partial<IRoomTypeCreate>) => void;
}> = ({ initialValues, onSubmit, id }) => {
  const isEdit = !!id;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={roomTypeValidationSchema}
      onSubmit={onSubmit}
      validateOnChange={true}
    >
      {({ dirty, submitForm }) => {
        return (
          <>
            <FlexCardRow type="flex" gutter={32}>
              <Col lg={{ span: 12 }} sm={{ span: 24 }}>
                <FlexGrowWithLabel>
                  <Label>Allgemeine Informationen:</Label>
                  <Card>
                    <FormItem name="name">
                      <NameInput name="name" placeholder="Name" autoFocus />
                    </FormItem>
                    <MainCardHead>
                      <div
                        style={{
                          flexGrow: 1
                        }}
                      >
                        <FormItem name="description">
                          <TextArea
                            name="description"
                            autoSize={{ minRows: 6, maxRows: 14 }}
                            placeholder="Beschreibungs-Text"
                          />
                        </FormItem>
                      </div>
                      <PreviewWrapper>
                        <Field name="previewSmallFileName">
                          {({ field: { onChange, value } }: FieldProps) => {
                            return (
                              <FormItem name="previewSmallFileName">
                                <RoomPreviewUpload
                                  value={value}
                                  onChange={(value: string) =>
                                    onChange({
                                      target: {
                                        name: "previewSmallFileName",
                                        value
                                      }
                                    })
                                  }
                                />
                              </FormItem>
                            );
                          }}
                        </Field>
                      </PreviewWrapper>
                    </MainCardHead>
                    <FieldRow>
                      <FormItem label="Preis (Werktag)" name="priceWorkDay">
                        <InputGroup compact>
                          <InputNumber
                            name="priceWorkDay"
                            style={{ width: 120, maxWidth: "100%" }}
                            min={0}
                            precision={2}
                            decimalSeparator=","
                          />
                          <Button disabled>€</Button>
                        </InputGroup>
                      </FormItem>
                      <FormItem label="Preis (Wochenende)" name="priceWeekend">
                        <InputGroup compact>
                          <InputNumber
                            name="priceWeekend"
                            style={{ width: 120, maxWidth: "100%" }}
                            min={0}
                            precision={2}
                            decimalSeparator=","
                          />
                          <Button disabled>€</Button>
                        </InputGroup>
                      </FormItem>
                      <FormItem label="Zimmergröße (qm)" name="sizeSQ">
                        <InputGroup compact>
                          <InputNumber
                            style={{ width: 120, maxWidth: "100%" }}
                            name="sizeSQ"
                            min={0}
                          />
                          <Button disabled>€</Button>
                        </InputGroup>
                      </FormItem>
                    </FieldRow>
                    <FieldRow>
                      <FormItem label="Guestline Link Filter" name="linkFilter">
                        <Input style={{ maxWidth: "100%" }} name="linkFilter" />
                        <Explanation>
                          Beispiel: <b>filters[rooms]=1_AMBIENTE</b>
                          <br />
                          Filter wie "1_AMBIENTE" sind in Guestline zu
                          verwalten.
                        </Explanation>
                      </FormItem>
                    </FieldRow>
                  </Card>
                </FlexGrowWithLabel>
              </Col>
              <Col lg={{ span: 12 }} sm={{ span: 24 }}>
                <FlexGrowWithLabel>
                  <Label>Bildergallerie:</Label>
                  <Card>
                    <Field name="images">
                      {({ field: { onChange, value } }: FieldProps) => {
                        return (
                          <FormItem name="images">
                            <RoomPictureWall
                              images={value}
                              onChange={value =>
                                onChange({
                                  target: { name: "images", value }
                                })
                              }
                            />
                          </FormItem>
                        );
                      }}
                    </Field>
                  </Card>
                </FlexGrowWithLabel>
              </Col>
            </FlexCardRow>
            <ButtonBar>
              {isEdit ? (
                <>
                  <DeleteRoomType id={id!} />
                  <Button disabled={!dirty} onClick={submitForm} type="primary">
                    Zimmer speichern
                  </Button>
                  <Link to=".">
                    <Button>Zurück</Button>
                  </Link>
                </>
              ) : (
                <Button type="primary" onClick={submitForm}>
                  Zimmer anlegen
                </Button>
              )}
            </ButtonBar>
          </>
        );
      }}
    </Formik>
  );
};
