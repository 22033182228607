import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Alert, Button, Modal, notification } from "antd";
import {
  ArrangementForm,
  arrangementValidationSchema,
  FormArrangement
} from "./components/ArrangementForm";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useAxios } from "../../util/useAxios";

type CreateArrangementProps = {
  onCreated: () => void;
};

const StyledButton = styled(Button)`
  margin-bottom: 1rem;
`;

export const FormBody = styled.div`
  .ant-form-item-label {
    line-height: 1;
    overflow: inherit;
  }
`;

const initialValues: FormArrangement = {
  title: undefined,
  text: undefined,
  imageFileName: undefined,
  pdfFileName: undefined,
  priceDZ: undefined,
  priceEZ: undefined,
  nights: undefined,
  timeFrame: undefined,
  isActive: false
};

export const CreateArrangement: React.FC<CreateArrangementProps> = ({
  onCreated
}) => {
  const [visible, setVisible] = useState();
  const toggle = () => setVisible(!visible);
  const [{ loading, error }, createArrangement] = useAxios({
    url: "/api/admin/arrangements",
    method: "POST",
    manual: true
  });

  const handleSubmit = async (
    { timeFrame, ...arrangement }: FormArrangement,
    { resetForm }: FormikHelpers<FormArrangement>
  ) => {
    const transformed = {
      ...arrangement,
      timeFrameStart: timeFrame![0],
      timeFrameEnd: timeFrame![1]
    };
    const { error, response } = await createArrangement({ data: transformed });
    if (!error && response.status === 201) {
      onCreated();
      notification.success({
        message: "Arrangement wurde erfolgreich erstellt."
      });
      toggle();
      resetForm();
    }
  };
  return (
    <>
      <StyledButton type="primary" icon="plus" onClick={toggle}>
        Neues Arrangement anlegen
      </StyledButton>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={arrangementValidationSchema}
      >
        {(formik: FormikProps<FormArrangement>) => {
          const { submitForm } = formik;
          return (
            <Modal
              visible={visible}
              onCancel={toggle}
              width={680}
              title="Neues Arrangement"
              footer={
                <>
                  <Button onClick={toggle}>Abbrechen</Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={submitForm}
                    loading={loading}
                  >
                    Arrangement anlegen
                  </Button>
                </>
              }
            >
              <FormBody>
                <ArrangementForm formik={formik} />
                {error && error.response && error.response.data && (
                  <Alert
                    type="error"
                    message="Arrangement konnte nicht erstellt werden."
                    description={`Interne Fehlermeldung: ${error.response.data}`}
                  />
                )}
              </FormBody>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
