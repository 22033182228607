import * as React from "react";
import { ReactNode } from "react";
import { Card, Empty, Statistic } from "antd";
import { getRoomPriceForDay, IRoomType } from "@accommod/common";
import styled from "styled-components";
import moment from "moment";
import { PriceOverrideModal } from "./PriceOverrideModal";

const InnerCard = styled(Card)`
  width: 20%;
  min-width: 300px;
  margin: 0.75rem;
  .ant-card-body {
    display: block;
    flex-direction: column;
  }
  .ant-statistic-title {
    margin-bottom: 0;
  }
  .ant-statistic-content {
    font-size: 1rem;
  }
  .ant-statistic-content-value-decimal {
    font-size: 0.85rem;
  }
`;
const Heading = styled.h3`
  font-weight: bold;
`;
const StatisticWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PriceOverrideCardContent: React.FC<{
  roomTypes: IRoomType[];
  reFetch: () => void;
}> = ({ roomTypes, reFetch }) => {
  if (!roomTypes || !roomTypes.length) {
    return <Empty description="Es sind noch keine Zimmer angelegt worden." />;
  }
  const day1 = moment().add(1, "day");
  const date1 = day1.format("YYYY-MM-DD");
  const day2 = moment().add(2, "days");
  const date2 = day2.format("YYYY-MM-DD");
  const day3 = moment().add(3, "days");
  const date3 = day3.format("YYYY-MM-DD");
  return (
    <>
      {roomTypes.map(r => {
        return (
          <InnerCard key={r.id}>
            <Heading>{r.name}</Heading>
            <StatisticWrapper>
              <Statistic
                value={
                  r.priceOverrides.find(po => po.date === date1)?.price ||
                  getRoomPriceForDay(r, date1)
                }
                decimalSeparator=","
                title={<b>{day1.format("ddd")} (Morgen)</b>}
                valueRender={(value: ReactNode) => <b>{value} €</b>}
                precision={2}
              />
              <Statistic
                value={
                  r.priceOverrides.find(po => po.date === date2)?.price ||
                  getRoomPriceForDay(r, date2)
                }
                decimalSeparator=","
                title={day2.format("ddd")}
                valueRender={(value: ReactNode) => <span>{value} €</span>}
                precision={2}
              />
              <Statistic
                value={
                  r.priceOverrides.find(po => po.date === date3)?.price ||
                  getRoomPriceForDay(r, date3)
                }
                decimalSeparator=","
                title={day3.format("ddd")}
                valueRender={(value: ReactNode) => <span>{value} €</span>}
                precision={2}
              />
            </StatisticWrapper>
            <PriceOverrideModal roomType={r} reFetch={reFetch} />
          </InnerCard>
        );
      })}
    </>
  );
};
