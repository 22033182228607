import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Alert, Button, Icon, Modal, notification } from "antd";
import { IArrangement } from "@accommod/common";
import { useAxios } from "../../util/useAxios";

type DeleteArrangementProps = {
  arrangement: IArrangement;
  onDelete: () => void;
};

const DeleteIcon = styled(Icon)`
  color: red;
  font-size: 1.3rem;
  cursor: pointer;
`;
export const DeleteArrangement: React.FC<DeleteArrangementProps> = ({
  onDelete,
  arrangement
}) => {
  const [visible, setVisible] = useState();
  const toggle = () => setVisible(!visible);

  const [{ loading, error }, deleteArrangement] = useAxios({
    url: "/api/admin/arrangements",
    method: "DELETE",
    manual: true
  });
  const handleDelete = async () => {
    const { id } = arrangement;
    const { error, response } = await deleteArrangement({ data: { id } });
    if (!error && response.status === 204) {
      onDelete();
      notification.success({
        message: "Arrangement wurde erfolgreich gelöscht."
      });
      toggle();
    }
  };
  return (
    <>
      <DeleteIcon type="delete" onClick={toggle} title="Arrangement löschen..."/>
      <Modal
        visible={visible}
        onCancel={toggle}
        title={`Wollen sie "${arrangement.title}" wirklich löschen?`}
        footer={
          <>
            <Button type="danger" onClick={handleDelete} loading={loading}>
              Arrangement löschen
            </Button>
            <Button onClick={toggle}>Abbrechen</Button>
          </>
        }
      >
        <p>
          Bedenken Sie: Es gibt auch die Möglichkeit, das Arrangement zu
          deaktivieren. Es erscheint dann nicht mehr auf der Website, bleibt
          aber in der Tabelle erhalten.
        </p>
        {error && error.response && error.response.data && (
          <Alert
            type="error"
            message="Arrangement konnte nicht gelöscht werden."
            description={`Interne Fehlermeldung: ${error.response.data}`}
          />
        )}
      </Modal>
    </>
  );
};
