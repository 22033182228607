"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var moment = require("moment");

function getRoomPriceForDay(roomType, date) {
  var dayOfWeek = moment(date).weekday();

  if (dayOfWeek === 0 || dayOfWeek === 6) {
    return roomType.priceWeekend;
  }

  return roomType.priceWorkDay;
}

exports.getRoomPriceForDay = getRoomPriceForDay;

exports.getTimeDiffInDays = function (arrival, departure) {
  return Math.abs(moment(arrival).diff(moment(departure), "days"));
};

exports.getPricePerNight = function (totalPrice, arrival, departure) {
  var timeDiff = exports.getTimeDiffInDays(arrival, departure);

  if (timeDiff < 1) {
    return null;
  }

  return totalPrice && typeof totalPrice === "number" ? totalPrice / timeDiff : null;
};