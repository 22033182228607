import * as React from "react";
import { AdminLayout } from "../../components/AdminLayout";
import { ContentWrapper } from "../../components/ContentWrapper";
import { Button } from "antd";
import { Link } from "react-router-dom";

export const NotFoundPage: React.FC = () => {
  return (
    <AdminLayout>
      <ContentWrapper>
        <h2>Für diese Url existiert kein Inhalt.</h2>
        <Link to="/">
          <Button>Zurück zur Startseite</Button>
        </Link>
      </ContentWrapper>
    </AdminLayout>
  );
};
