import * as React from "react";
import { FormEvent } from "react";
import { Alert, Button, Form, Icon, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import styled from "styled-components";
import { useAxios } from "../../util/useAxios";

const FullWidthButton = styled(Button)`
  width: 100%;
`;
type LoginFormInnerProps = { onFinish: () => void };

const LoginFormInner: React.FC<LoginFormInnerProps & FormComponentProps> = ({
  form: { getFieldDecorator, validateFields },
  onFinish
}) => {
  const [{ loading, error }, login] = useAxios({
    url: "/api/admin/access/login",
    method: "POST",
    manual: true
  });
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    validateFields(async (err, { password }) => {
      if (!err) {
        const { error, response } = await login({ data: { password } });
        if (!error && response.status === 200) {
          onFinish();
        }
      }
    });
  };
  return (
    <Form onSubmit={handleSubmit} className="login-form">
      <Form.Item>
        {getFieldDecorator("password", {
          rules: [
            { required: true, message: "Bitte geben Sie das Passwort ein!" }
          ]
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder="Password"
          />
        )}
      </Form.Item>
      {error && error.response && (
        <Alert
          type="error"
          message="Login nicht erfolgreich."
          style={{ marginBottom: "1rem" }}
          description={error.response.data || undefined}
        />
      )}
      <Form.Item>
        <FullWidthButton
          loading={loading}
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          Log in
        </FullWidthButton>
      </Form.Item>
    </Form>
  );
};
export const LoginForm = Form.create<LoginFormInnerProps & FormComponentProps>({
  name: "normal_login"
})(LoginFormInner);
