"use strict";

var _slicedToArray = require("/var/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var types_1 = require("../types");

exports.convertStringToBeds = function (bedNotation) {
  var entries = bedNotation.split(";");

  var getNumberForShortString = function getNumberForShortString(short) {
    var entry = entries.find(function (e) {
      return e.indexOf(short) !== -1;
    });
    return entry ? parseInt(entry) : 0;
  };

  return {
    childrenBeds: getNumberForShortString("cb"),
    doubleBeds: getNumberForShortString("db"),
    doubleBunkBeds: getNumberForShortString("dbb"),
    doubleCouch: getNumberForShortString("dc"),
    singleBeds: getNumberForShortString("sb"),
    singleCouch: getNumberForShortString("sc"),
    tripleBunkBeds: getNumberForShortString("tbb")
  };
};

exports.convertBedsToString = function (beds) {
  return Object.entries(beds).reduce(function (acc, _ref, index) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    var bedNotation = "".concat(value).concat(types_1.BedNotationShortMap[key]);

    if (index > 0) {
      return "".concat(acc, ";").concat(bedNotation);
    }

    return "".concat(value).concat(bedNotation);
  }, "");
};

exports.getCapacityFromBeds = function (beds) {
  return Object.entries(beds).reduce(function (acc, _ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
        key = _ref4[0],
        value = _ref4[1];

    return acc + (value ? types_1.BedNotationCapacityMap[key] * value : 0);
  }, 0);
};

exports.extractCapacity = function (beds) {
  var capacity = 2;

  try {
    var bedNotation = exports.convertStringToBeds(beds);
    return exports.getCapacityFromBeds(bedNotation);
  } catch (e) {
    return capacity;
  }
};

exports.extractAdultCapacity = function (roomType) {
  if (!roomType) {
    return undefined;
  }

  try {
    var _a = exports.convertStringToBeds(roomType.beds),
        childrenBeds = _a.childrenBeds,
        adultBeds = __rest(_a, ["childrenBeds"]);

    return exports.getCapacityFromBeds(adultBeds);
  } catch (e) {
    return undefined;
  }
};