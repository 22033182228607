import * as React from "react";
import { useAxios } from "../util/useAxios";
import { GlobalPrice, GlobalPriceKey } from "@accommod/common";
import { Badge, Icon, notification } from "antd";
import useToggle from "../util/useToggle";
import styled from "styled-components";

const BadgeIcon = styled(Icon)`
  margin: 0 !important;
  padding-left: 10px;
  color: #f5222d;
  font-size: 1rem;
`;

export const RatesReminder: React.FC = ({ children }) => {
  const [show, toggle] = useToggle();
  useAxios<GlobalPrice[]>({
    onSuccess: ({ data }) => {
      if (!data || data.length < Object.keys(GlobalPriceKey).length) {
        notification.warn({
          message: "Das System ist noch nicht vollständig konfiguriert.",
          description: `Bitte tragen Sie die Pauschalen ein.`
        });
        toggle();
      }
    },
    onError: () => {
      notification.error({
        message: "Es ist ein Fehler beim laden der Pauschalen aufgetreten.",
        description: `Bitte überprüfen Sie die Pauschalen`
      });
      toggle();
    },
    url: "/api/admin/global-prices"
  });
  return show ? (
    <Badge count={<BadgeIcon type="exclamation-circle" theme="filled" />}>
      {children}
    </Badge>
  ) : (
    <>{children}</>
  );
};
