import * as React from "react";
import { useState } from "react";
import { UploadChangeParam } from "antd/lib/upload";
import { Icon, notification, Upload } from "antd";
import styled from "styled-components";

type ArrangementPDFUploadProps = {
  onChange: (value: string) => void;
  value?: string;
};
const OpenPDFLink = styled.a`
  display: block;
  margin-top: 0.5rem;
`;
export const ArrangementPDFUpload: React.FC<ArrangementPDFUploadProps> = ({
  value,
  onChange
}) => {
  const [loading, setLoading] = useState(false);

  const handleChange = (info: UploadChangeParam) => {
    switch (info.file.status) {
      case "uploading":
        if (!loading) {
          setLoading(true);
        }
        break;
      case "error":
        notification.error({
          message: "Upload fehlgeschlagen",
          description: `Es ist ein Fehler beim Hochladen aufgetreten.${
            info.file.response && info.file.response.length
              ? ` Interne Fehlermeldung: ${info.file.response}`
              : ""
          }`
        });
        setLoading(false);
        break;
      case "done":
        setLoading(false);
        onChange(info.file.response);
        break;
    }
  };
  return (
    <Upload
      accept="application/pdf"
      multiple={false}
      listType="picture-card"
      action="/api/admin/arrangements/upload/pdf"
      showUploadList={false}
      onChange={handleChange}
    >
      {loading ? (
        <Icon type="loading" style={{ fontSize: "2rem" }} />
      ) : value ? (
        <>
          <Icon type="file-pdf" style={{ fontSize: "3rem" }} />
          <OpenPDFLink
            onClick={e => e.stopPropagation()}
            href={`/e-static/arrangement-pdfs/${value}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            PDF öffnen
          </OpenPDFLink>
        </>
      ) : (
        <div>
          <div className="ant-upload-text">PDF hochladen</div>
        </div>
      )}
    </Upload>
  );
};
