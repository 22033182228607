import React, { useEffect, useState } from "react";
import { Button, Icon, InputNumber, notification, Popover } from "antd";
import { IRoomType, RoomPriceOverride } from "@accommod/common";
import useToggle from "../../../util/useToggle";
import styled from "styled-components";
import moment from "moment";
import { InputGroup } from "../../FormItem";
import { useAxios } from "../../../util/useAxios";

const EditIcon = styled(Icon)`
  font-size: 2rem;
`;

const PopoverHeading = styled.h2`
  font-size: 1rem;
  margin: 0;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PriceOverride: React.FC<{
  roomType: IRoomType;
  date: moment.Moment;
  onOverride: () => void;
  currentOverride?: RoomPriceOverride;
}> = ({ onOverride, roomType, date, currentOverride }) => {
  const [popupVisible, togglePopup] = useToggle();

  const [newOverride, setNewOverride] = useState<number | undefined>(undefined);

  useEffect(() => {
    setNewOverride(currentOverride ? currentOverride.price : undefined);
  }, [currentOverride]);

  const [{ loading }, request] = useAxios({
    url: "/api/admin/room-types/price-overrides",
    method: "POST",
    manual: true,
    onError: error => {
      notification.error({
        message: "Der Preis konnte nicht gespeichert werden.",
        description:
          error && error.response
            ? `Interne Fehlermeldung: ${error.response.data}`
            : undefined
      });
    },
    onSuccess: () => {
      notification.success({ message: "Der Preis wurde gespeichert." });
      togglePopup();
      onOverride();
    }
  });

  const handleSubmit = () => {
    request({
      data: {
        date,
        roomTypeId: roomType.id,
        price: newOverride
      }
    });
  };

  return (
    <Popover
      content={
        <InputRow>
          <InputGroup compact>
            <InputNumber
              min={0}
              value={newOverride}
              onChange={setNewOverride}
              precision={2}
              decimalSeparator=","
            />
            <Button disabled>€</Button>
          </InputGroup>
          <Button
            type="primary"
            loading={loading}
            onClick={handleSubmit}
            disabled={newOverride === currentOverride || !newOverride}
          >
            Preis überschreiben
          </Button>
        </InputRow>
      }
      visible={popupVisible}
      trigger="click"
      onVisibleChange={togglePopup}
      title={
        <PopoverHeading>
          Preis für {date.format("LL")} überschreiben
        </PopoverHeading>
      }
    >
      <EditIcon type="edit" title="Preis ändern" />
    </Popover>
  );
};
