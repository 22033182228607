import { Button, notification, Popconfirm } from "antd";
import * as React from "react";
import { useAxios } from "../../../util/useAxios";
import { useHistory } from "react-router-dom";

export const DeleteRoomType: React.FC<{ id: string }> = ({ id }) => {
  const { push } = useHistory();
  const [{ loading }, deleteRoomType] = useAxios({
    url: "/api/admin/room-types",
    method: "DELETE",
    manual: true,
    data: { id },
    onSuccess: () => {
      notification.success({
        message: "Zimmer-Typ wurde erfolgreich gelöscht."
      });
      push("/room-types");
    },
    onError: error => {
      notification.error({
        message: "Ein Fehler ist aufgetreten.",
        description: `Grund: ${
          error && error.response ? error.response.data : "Internal error"
        }`
      });
    }
  });
  return (
    <Popconfirm
      title="Sind Sie sich sicher? Dieser Schritt ist nicht reversibel!"
      onConfirm={() => deleteRoomType()}
      okButtonProps={{ loading }}
    >
      <Button type="danger">Zimmer löschen</Button>
    </Popconfirm>
  );
};
