import * as React from "react";
import styled from "styled-components";
import { Popover } from "antd";

type LongTextCoverProps = {};
const TextWrapper = styled.span`
  white-space: nowrap;
  max-width: 12rem;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
`;

export const LongTextCover: React.FC<LongTextCoverProps> = ({ children }) => (
  <>
    <Popover content={children} overlayStyle={{ maxWidth: "30rem" }}>
      <TextWrapper>{children}</TextWrapper>
    </Popover>
  </>
);
