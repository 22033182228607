import * as React from "react";
import styled from "styled-components";
import { notification } from "antd";
import { IRoomTypeCreate } from "@accommod/common";
import { useHistory } from "react-router-dom";
import { useAxios } from "../../../util/useAxios";
import { RoomTypeForm } from "../components/RoomTypeForm";

export const Container = styled.div`
  padding: 0.75rem;
  max-width: 1400px;
  .ant-form-item-label {
    line-height: 1;
    overflow: inherit;
  }
`;

export const CreateRoomType = () => {
  const { push } = useHistory();
  const [{ loading }, createRoomType] = useAxios({
    url: "/api/admin/room-types",
    method: "POST",
    manual: true,
    onError: error => {
      notification.error({
        message: "Zimmer-Typ konnte nicht angelegt werden.",
        description: error.response
          ? `Grund: ${error.response.data}`
          : undefined
      });
    },
    onSuccess: () => {
      notification.success({
        message: "Zimmer-Typ wurde erfolgreich angelegt."
      });
      push("/room-types/");
    }
  });

  const handleSubmit = (roomType: Partial<IRoomTypeCreate>) => {
    createRoomType({ data: roomType });
  };
  return (
    <Container>
      <RoomTypeForm
        loading={loading}
        onSubmit={handleSubmit}
        initialValues={{
          images: undefined,
          beds: "1db",
          name: "",
          description: "",
          previewSmallFileName: "",
          linkFilter: "",
          priceWeekend: undefined,
          priceWorkDay: undefined,
          sizeSQ: undefined
        }}
      />
    </Container>
  );
};
