import * as React from "react";
import { Card, Icon } from "antd";
import Meta from "antd/es/card/Meta";
import styled from "styled-components";
import { IRoomType, MoveDirection } from "@accommod/common";
import { MoveRoomType } from "./MoveRoomType";
import { Link } from "react-router-dom";
import { PriceOverrideModal } from "../../../../components/dashboard-tiles/price-override/PriceOverrideModal";

export const RoomCardContainer = styled.div`
  width: 240px;
  margin: 0.75rem;
`;

export const StyledRoomCard = styled(Card)`
  .ant-card-meta-description {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-card-cover {
    img {
      height: 9rem;
      object-fit: cover;
    }
  }
`;

type RoomTypeCardProps = {
  roomType: IRoomType;
  index: number;
  itemCount: number;
  reFetch: () => void;
};

export const RoomTypeCard: React.FC<RoomTypeCardProps> = ({
  roomType,
  index,
  reFetch,
  itemCount
}) => {
  const {
    id,
    orderNumber,
    description,
    previewSmallFileName,
    images,
    name,
    priceWeekend,
    priceWorkDay
  } = roomType;
  let coverImageSrc = `/e-static/room-previews/${previewSmallFileName}`;
  if (Array.isArray(images) && images.length) {
    const sorted = images.sort((i1, i2) => i1.orderNumber - i2.orderNumber);
    coverImageSrc = `/e-static/room-images/${sorted[0].fileName}`;
  }
  return (
    <RoomCardContainer>
      <StyledRoomCard
        cover={<img alt="room image preview" src={coverImageSrc} />}
        actions={[
          <MoveRoomType
            orderNumber={orderNumber}
            index={index}
            itemCount={itemCount}
            direction={MoveDirection.Up}
            reFetch={reFetch}
          />,
          <PriceOverrideModal
            roomType={roomType}
            renderToggle={onToggle => (
              <Icon
                type="dollar"
                title="Zimmerpreise anpassen"
                onClick={onToggle}
              />
            )}
          />,
          <Link to={`/room-types/${id}`} title="Zimmer konfigurieren">
            <Icon type="setting" />
          </Link>,
          <MoveRoomType
            orderNumber={orderNumber}
            direction={MoveDirection.Down}
            index={index}
            itemCount={itemCount}
            reFetch={reFetch}
          />
        ]}
      >
        <Meta
          title={<Link to={`/room-types/${id}`}>{name}</Link>}
          description={description}
        />
        <br />
        <b>{priceWorkDay}€</b> (Wochentag)
        <br />
        <b>{priceWeekend}€</b> (Wochenende)
      </StyledRoomCard>
    </RoomCardContainer>
  );
};
