import React, { ReactNode, useState } from "react";
import { Button, Modal } from "antd";
import styled from "styled-components";
import { IRoomType } from "@accommod/common";
import { PriceOverrideCalendar } from "./PriceOverrideCalendar";

const CalendarModal = styled(Modal)`
  top: 20px;
  .ant-modal-body {
    padding: 0;
    min-height: 800px;
    .ant-fullcalendar-calendar-body {
      padding: 0.25rem;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1rem 0 0 0;
`;

export const PriceOverrideModal: React.FC<{
  reFetch?: () => void;
  roomType: IRoomType;
  renderToggle?: (onToggle: () => void) => ReactNode;
}> = ({ roomType, renderToggle, reFetch }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const toggleModal = () => {
    if (modalVisible && typeof reFetch === "function") {
      reFetch();
    }
    setModalVisible(!modalVisible);
  };
  return (
    <>
      {renderToggle ? (
        renderToggle(toggleModal)
      ) : (
        <ButtonContainer>
          <Button type="dashed" onClick={toggleModal}>
            Preise anpassen
          </Button>
        </ButtonContainer>
      )}
      <CalendarModal
        width={1000}
        title="Zimmerpreise überschreiben"
        visible={modalVisible}
        onCancel={toggleModal}
        footer={<Button onClick={toggleModal}>Kalender schließen</Button>}
      >
        {modalVisible && <PriceOverrideCalendar roomType={roomType} />}
      </CalendarModal>
    </>
  );
};
