import * as React from "react";
import { useState } from "react";
import { RoomImage } from "@accommod/common";
import { Icon, Modal } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import styled from "styled-components";
import { Upload } from "../../../components/Upload";

const PreviewImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: calc(100vh - 4rem);
`;

const PreviewModal = styled(Modal)`
  top: 0;
  .ant-modal-content {
    height: 100vh;
    border-radius: 0;
  }
  .ant-modal-body {
    padding: 2rem 1.5rem 1rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-height: 100%;
  }
`;

export const RoomPictureWall: React.FC<{
  images?: RoomImage[];
  onChange: (value: RoomImage[]) => void;
}> = ({ images = [], onChange }) => {
  const sorted = images.sort((i1, i2) => i1.orderNumber - i2.orderNumber);

  const [state, setState] = useState<{
    previewVisible: boolean;
    previewModalImageFile: string;
    fileList: any;
  }>({
    fileList: sorted.map(image => ({
      uid: image.fileName,
      name: image.fileName,
      status: "done",
      url: `/e-static/room-images/${image.fileName}`
    })),
    previewModalImageFile: "",
    previewVisible: false
  });

  const { fileList, previewModalImageFile, previewVisible } = state;

  const handleChange = (change: UploadChangeParam) => {
    setState({
      ...state,
      fileList: change.fileList.map(file => {
        if (file.response) {
          file.url = `/e-static/room-images/${file.response}`;
        }
        return file;
      })
    });
    onChange(
      change.fileList.reduce((acc, file, i) => {
        if (file.status === "done") {
          acc.push({
            fileName: file.response || file.name,
            orderNumber: i + 1
          });
        }
        return acc;
      }, [] as any[])
    );
  };

  const handlePreview = async (file: UploadFile) => {
    const imageFile = file.url || file.preview;
    if (imageFile) {
      setState({
        ...state,
        previewVisible: true,
        previewModalImageFile: imageFile
      });
    }
  };

  const handleCancel = () => setState({ ...state, previewVisible: false });

  return (
    <>
      <Upload
        action="/api/admin/room-types/upload/images"
        listType="picture-card"
        fileList={fileList}
        multiple
        onPreview={handlePreview}
        onChange={handleChange}
      >
        {fileList.length < 8 && (
          <div>
            <div className="ant-upload-text">
              Bild
              <br />
              hinzufügen
            </div>
            <Icon
              type="plus"
              style={{ fontSize: "1.5rem", marginTop: "0.25rem" }}
            />
          </div>
        )}
      </Upload>
      <PreviewModal
        visible={previewVisible}
        width={"100%"}
        footer={null}
        onCancel={handleCancel}
      >
        <PreviewImage alt="Preview" src={previewModalImageFile} />
      </PreviewModal>
    </>
  );
};
