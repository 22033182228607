import styled from "styled-components";
import { Upload as AntdUpload } from "antd";

export const Upload = styled(AntdUpload)`
  .ant-upload-list-item > div {
    height: 100%;
  }
  img {
    max-height: 86px;
  }
`;
