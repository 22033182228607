import * as React from "react";
import { PriceOverride } from "./PriceOverridePopup";
import styled from "styled-components";
import moment from "moment";
import {
  getRoomPriceForDay,
  IRoomType,
  RoomPriceOverride
} from "@accommod/common";

const PriceDisplayContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  height: 100%;
  padding: 0.5rem 0;
`;

const PriceContainer = styled.div`
  margin-left: auto;
  text-align: right;
`;

const CrossedPrice = styled.div`
  text-decoration: line-through;
  font-size: 0.75rem;
  margin-left: 0.375rem;
`;

const BoldPrice = styled.div`
  font-weight: bold;
  font-size: 0.85rem;
`;

export const DateCellContent: React.FC<{
  roomType: IRoomType;
  date: moment.Moment;
  today: moment.Moment;
  isSelected: boolean;
  override?: RoomPriceOverride;
  onOverride: () => void;
}> = ({ onOverride, roomType, date, today, isSelected, override }) => {
  const defaultPrice = getRoomPriceForDay(roomType, date.format("YYYY-MM-DD"));

  return (
    <PriceDisplayContainer>
      {isSelected && date.isAfter(today) && (
        <PriceOverride
          onOverride={onOverride}
          currentOverride={override}
          roomType={roomType}
          date={date}
        />
      )}
      <PriceContainer>
        {override ? (
          <>
            <BoldPrice>{override.price}€</BoldPrice>
            <CrossedPrice>{defaultPrice}€</CrossedPrice>
          </>
        ) : (
          <BoldPrice>{defaultPrice}€</BoldPrice>
        )}
      </PriceContainer>
    </PriceDisplayContainer>
  );
};
