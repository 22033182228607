import styled from "styled-components";
import { Form } from "formik-antd";
import { Input } from "antd";

export const FormItem = styled(Form.Item)`
  .has-error {
    .ant-upload {
      border-color: red;
    }
  }
`;

export const InputGroup = styled(Input.Group)`
  margin: 0.25rem 0;
`;
