import * as React from "react";
import { AdminLayout } from "components/AdminLayout";
import styled from "styled-components";
import { useAxios } from "../../util/useAxios";
import { GlobalPrice } from "@accommod/common";
import {
  DashboardTile,
  TileContainer
} from "../../components/dashboard-tiles/components/DashboardTile";
import { Alert } from "antd";
import { GlobalPricesForm } from "../../components/GlobalPricesForm";

const ContentWrapper = styled.div`
  width: 100%;
  padding: 2rem;
`;

export const RatesPage: React.FC = () => {
  const [{ loading, error, data }] = useAxios<GlobalPrice[]>({
    url: "/api/admin/global-prices"
  });
  return (
    <AdminLayout>
      <ContentWrapper>
        <h2>Pauschalen</h2>
        <DashboardTile loading={loading} style={{ maxWidth: "720px" }}>
          <TileContainer>
            {error || !data ? (
              <Alert
                type="error"
                message="Die Pauschalen konnten nicht geladen werden."
                description={
                  error && error.response
                    ? `Fehlermeldung: ${error.response.data}`
                    : undefined
                }
              />
            ) : (
              <GlobalPricesForm globalPrices={data} />
            )}
          </TileContainer>
        </DashboardTile>
      </ContentWrapper>
    </AdminLayout>
  );
};
