import * as React from "react";
import { useState } from "react";
import { Alert, Button, Icon, Modal, notification } from "antd";
import { Formik, FormikHelpers } from "formik";
import { FormBody } from "../../arrangements/CreateArrangement";
import {
  UpdatePasswordForm,
  updatePasswordValidationSchema
} from "./UpdatePasswordForm";
import { useAxios } from "../../../util/useAxios";

type UpdatePasswordProps = {};

export type UpdatePasswordFormValues = {
  currentPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
};

export const UpdatePassword: React.FC<UpdatePasswordProps> = () => {
  const [visible, setVisible] = useState();
  const toggle = () => setVisible(!visible);
  const [{ loading, error }, updatePassword] = useAxios({
    url: "/api/admin/system-config/update-password",
    method: "POST",
    manual: true
  });
  const handleSubmit = async (
    { currentPassword, newPassword }: UpdatePasswordFormValues,
    { resetForm }: FormikHelpers<UpdatePasswordFormValues>
  ) => {
    const { error, response } = await updatePassword({
      data: {
        currentPassword,
        newPassword
      }
    });
    if (!error && response.status === 200) {
      notification.success({
        message: "Das neue Passwort wurde erfolgreich gespeichert."
      });
      resetForm();
      toggle();
    }
  };
  return (
    <>
      <div onClick={toggle}>
        <Icon type="lock" /> Neues Passwort setzen
      </div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          currentPassword: "",
          newPassword: "",
          newPasswordRepeat: ""
        }}
        validationSchema={updatePasswordValidationSchema}
      >
        {formik => {
          const { submitForm } = formik;
          return (
            <Modal
              visible={visible}
              onCancel={toggle}
              title="Neues Passwort setzen"
              footer={
                <>
                  <Button onClick={toggle}>Abbrechen</Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={submitForm}
                    loading={loading}
                  >
                    Neues Passwort speichern
                  </Button>
                </>
              }
            >
              <FormBody>
                <UpdatePasswordForm formik={formik} />
                {error && error.response && error.response.data && (
                  <Alert
                    type="error"
                    message="Passwort konnte nicht gespeichert werden."
                    description={`Interne Fehlermeldung: ${
                      error.response.data
                    }`}
                  />
                )}
              </FormBody>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};
