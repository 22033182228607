import * as React from "react";
import { useContext } from "react";
import { AdminLayout } from "components/AdminLayout";
import styled from "styled-components";
import { SettingsForm } from "./components/SettingsForm";
import { SettingsContext } from "../../AppRouter";

const ContentWrapper = styled.div`
  width: 100%;
  padding: 2rem;
`;
const FormWrapper = styled.div`
  max-width: 540px;
`;

export const SettingsPage: React.FC = () => {
  const { settings, reFetch } = useContext(SettingsContext);
  return (
    <AdminLayout>
      <ContentWrapper>
        <h2>Einstellungen</h2>
        <FormWrapper>
          <SettingsForm onUpdate={reFetch} settings={settings} />
        </FormWrapper>
      </ContentWrapper>
    </AdminLayout>
  );
};
