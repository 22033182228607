import * as React from "react";
import { AdminLayout } from "components/AdminLayout";
import styled from "styled-components";
import { PriceOverrideTile } from "../../components/dashboard-tiles/price-override";

const ContentWrapper = styled.div`
  width: 100%;
  padding: 2rem;
`;

export const MainPage: React.FC = () => {
  return (
    <AdminLayout>
      <ContentWrapper>
        <h2>Startseite</h2>
        <PriceOverrideTile />
      </ContentWrapper>
    </AdminLayout>
  );
};
