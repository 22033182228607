import * as React from "react";
import { useState } from "react";
import { UploadChangeParam } from "antd/lib/upload";
import { Icon, notification, Upload } from "antd";

type ArrangementImageUploadProps = {
  onChange: (value: string) => void;
  value?: string;
};

export const ArrangementImageUpload: React.FC<ArrangementImageUploadProps> = ({
  value,
  onChange
}) => {
  const [loading, setLoading] = useState(false);

  const handleChange = (info: UploadChangeParam) => {
    switch (info.file.status) {
      case "uploading":
        if (!loading) {
          setLoading(true);
        }
        break;
      case "error":
        notification.error({
          message: "Upload fehlgeschlagen",
          description: `Es ist ein Fehler beim Hochladen aufgetreten.${
            info.file.response && info.file.response.length
              ? ` Interne Fehlermeldung: ${info.file.response}`
              : ""
          }`
        });
        setLoading(false);
        break;
      case "done":
        setLoading(false);
        onChange(info.file.response);
        break;
    }
  };
  return (
    <Upload
      accept="image/*"
      multiple={false}
      listType="picture-card"
      action="/api/admin/arrangements/upload/image"
      showUploadList={false}
      onChange={handleChange}
    >
      {loading ? (
        <Icon type="loading" style={{ fontSize: "2rem" }} />
      ) : value ? (
        <img
          src={`/e-static/arrangement-images/${value}`}
          alt="Arrangement Bild"
          style={{ width: "100%" }}
        />
      ) : (
        <div>
          <div className="ant-upload-text">Bild hochladen</div>
        </div>
      )}
    </Upload>
  );
};
