import * as React from "react";
import styled from "styled-components";
import { AdminLayout } from "../../components/AdminLayout";
import { Alert } from "antd";
import { ArrangementsTable } from "./ArrangementsTable";
import { CreateArrangement } from "./CreateArrangement";
import { useAxios } from "../../util/useAxios";

const ContentWrapper = styled.div`
  width: 100%;
  padding: 2rem;
`;

export const ArrangementsPage: React.FC = () => {
  const [{ loading, error, data }, reFetch] = useAxios({
    url: "/api/admin/arrangements"
  });
  return (
    <AdminLayout>
      <ContentWrapper>
        <h2>Arrangements</h2>
        <CreateArrangement onCreated={reFetch} />
        {!loading && (error || !data) && (
          <Alert
            style={{ margin: "2rem 0" }}
            type="error"
            message="Beim Laden der Daten ist ein Fehler aufgetreten."
            description={
              error && error.response ? error.response.data : undefined
            }
          />
        )}
        <ArrangementsTable
          loading={loading}
          reFetch={reFetch}
          data={Array.isArray(data) ? data : []}
        />
      </ContentWrapper>
    </AdminLayout>
  );
};
