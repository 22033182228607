"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var GlobalPriceKey;

(function (GlobalPriceKey) {
  GlobalPriceKey["ExtraPersonRate"] = "extraPersonRate";
  GlobalPriceKey["ExtraChildRate"] = "extraChildRate";
  GlobalPriceKey["FullConferenceRate"] = "fullConferenceRate";
  GlobalPriceKey["HalfConferenceRate"] = "halfConferenceRate";
  GlobalPriceKey["DinnerConferenceRate"] = "dinnerConferenceRate";
  GlobalPriceKey["CoffeeConferenceRate"] = "coffeeConferenceRate";
})(GlobalPriceKey = exports.GlobalPriceKey || (exports.GlobalPriceKey = {}));