import * as React from "react";
import { IArrangement } from "@accommod/common";
import { notification, Popconfirm, Switch } from "antd";
import { useAxios } from "../../../util/useAxios";

type SwitchActivationProps = {
  arrangement: IArrangement;
  onSwitch: () => void;
};

export const SwitchActivation: React.FC<SwitchActivationProps> = ({
  arrangement,
  onSwitch
}) => {
  const [{ loading }, editArrangement] = useAxios({
    url: "/api/admin/arrangements/toggle-activation",
    method: "POST",
    manual: true
  });
  const handleSwitch = async () => {
    const { error, response } = await editArrangement({
      data: { id: arrangement.id }
    });
    if (!error && response.status === 200) {
      notification.success({
        message: "Arrangement wurde erfolgreich aktiviert."
      });
      onSwitch();
    } else {
      notification.error({
        message: "Arrangement konnte nicht aktiviert werden.",
        description:
          error && error.response
            ? `Interne Fehlermeldung: ${error.response.data}`
            : undefined
      });
    }
  };
  return (
    <Popconfirm
      title={`Sind Sie sicher, dass Sie dieses Arrangement ${
        arrangement.isActive ? "deaktivieren" : "aktivieren"
      } möchten?`}
      okButtonProps={{ loading }}
      cancelText="Abbrechen"
      okText="Ich bin sicher!"
      onConfirm={handleSwitch}
    >
      <Switch
        checked={arrangement.isActive}
        title={`Arrangement ${
          arrangement.isActive ? "deaktivieren" : "aktivieren"
        }`}
      />
    </Popconfirm>
  );
};
