import React, { useEffect, useState } from "react";
import { Calendar } from "antd";
import styled from "styled-components";
import moment from "moment";
import { IRoomType, RoomPriceOverride } from "@accommod/common";
import { useAxios } from "../../../util/useAxios";
import { DateCellContent } from "./DateCellContent";
import { TakeSpaceSpinner } from "../../TakeSpaceSpinner";

const StyledCalendar = styled(Calendar)`
  .ant-fullcalendar-date.disabled {
    color: rgba(0, 0, 0, 0.125);
  }
`;

export const PriceOverrideCalendar: React.FC<{
  roomType: IRoomType;
}> = ({ roomType }) => {
  const today = moment();
  const [selectedDate, setSelectedDate] = useState<moment.Moment | undefined>(
    moment().add(1, "day")
  );
  const [monthDate, setMonthDate] = useState<moment.Moment>(
    moment().add(1, "day")
  );

  const [{ loading, data: priceOverrides }, request] = useAxios<
    RoomPriceOverride[]
  >({
    method: "GET",
    url: `/api/admin/room-types/price-overrides/${roomType.id}`,
    manual: true
  });

  const loadOverrides = () => {
    const from = moment(monthDate)
      .subtract(1, "months")
      .startOf("month");
    const to = moment(monthDate)
      .add(1, "months")
      .endOf("month");
    request({
      params: {
        from: from.toDate(),
        to: to.toDate()
      }
    });
  };

  useEffect(() => {
    loadOverrides();
  }, [monthDate]);

  const onChange = (date?: moment.Moment) => {
    if (date) {
      setSelectedDate(date);
      if (date.format("YYYY-MM") !== monthDate.format("YYYY-MM")) {
        setMonthDate(date);
      }
    }
  };
  return (
    <>
      <TakeSpaceSpinner loading={loading} />
      <StyledCalendar
        dateFullCellRender={date => (
          <div
            className={`ant-fullcalendar-date ${
              date.isBefore(today) ? "disabled" : ""
            }`}
          >
            <div className="ant-fullcalendar-value">{date.date()}</div>
            <div className="ant-fullcalendar-content">
              <DateCellContent
                date={date}
                onOverride={loadOverrides}
                override={
                  Array.isArray(priceOverrides)
                    ? priceOverrides.find(
                        override => override.date === date.format("YYYY-MM-DD")
                      )
                    : undefined
                }
                roomType={roomType}
                isSelected={date.isSame(selectedDate)}
                today={today}
              />
            </div>
          </div>
        )}
        value={selectedDate}
        onSelect={onChange}
        onPanelChange={onChange}
      />
    </>
  );
};
