import React, { createContext } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { FullscreenSpinner } from "./components/FullscreenSpinner";
import { Alert, Button, Card } from "antd";
import styled from "styled-components";
import { SetupForm } from "./pages/pre/SetupForm";
import { LoginForm } from "./pages/pre/LoginForm";
import { ArrangementsPage } from "./pages/arrangements";
import { MainPage } from "./pages/main";
import { SettingsPage } from "./pages/settings";
import { AppState, SystemSettingKey } from "@accommod/common";
import { useAxios } from "./util/useAxios";
import { RoomTypesPage } from "./pages/room-types";
import { NotFoundPage } from "./pages/404";
import { RatesPage } from "./pages/rates";

export const InternCard = styled(Card)`
  margin: 10rem auto;
  max-width: 460px;
`;
export const SettingsContext = createContext<{
  settings: {
    [key in Exclude<SystemSettingKey, SystemSettingKey.Password>]: any;
  };
  reFetch: () => void;
}>({
  settings: { bookingEmail: "", hotelName: "HOTEL" },
  reFetch: () => undefined
});

export const AppRouter = () => {
  const [{ data, loading, error }, reFetch] = useAxios({
    url: "/api/admin/system-config/app-state"
  });

  if (loading) {
    return <FullscreenSpinner />;
  }

  if (error && error.response && error.response.status === 401) {
    return (
      <InternCard title="Interner Zugang">
        <LoginForm onFinish={reFetch} />
      </InternCard>
    );
  }
  if (data && data.appState === AppState.NotSetup) {
    return (
      <InternCard title="Interner Zugang">
        <SetupForm onFinish={reFetch} />
      </InternCard>
    );
  }
  if (data && data.settings) {
    return (
      <SettingsContext.Provider value={{ settings: data.settings, reFetch }}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={MainPage} />
            <Route exact path="/arrangements" component={ArrangementsPage} />
            <Route exact path="/rates" component={RatesPage} />
            <Route path="/room-types" component={RoomTypesPage} />
            <Route exact path="/settings" component={SettingsPage} />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </BrowserRouter>
      </SettingsContext.Provider>
    );
  }
  return (
    <InternCard title="Interner Zugang">
      <Alert
        message="Ein interner Fehler ist aufgetreten."
        type="error"
        style={{ marginBottom: "1.5rem" }}
      >
        {error ? error.message : "Das System antwortet nicht wie erwartet."}
      </Alert>
      <Button onClick={() => reFetch()}>Neu laden</Button>
    </InternCard>
  );
};
