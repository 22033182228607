import * as React from "react";
import { FormEvent } from "react";
import { Alert, Button, Form, Icon, Input, Tooltip } from "antd";
import { FormComponentProps } from "antd/lib/form";
import styled from "styled-components";
import * as Yup from "yup";
import { useAxios } from "../../util/useAxios";

const validate = (values: any) =>
  Yup.object({
    setupPassword: Yup.string(),
    bookingEmail: Yup.string()
      .email("Das ist keine valide Email Adresse.")
      .required(),
    password: Yup.string()
      .min(8, "Ihr Passwort sollte mindestens 8 Zeichen lang sein.")
      .max(255, "Ihr Passwort sollte weniger als 255 Zeichen lang sein."),
    passwordRepeat: Yup.string().test(
      "is-repeat",
      "Die beiden Passwörter sind nicht gleich.",
      repeatValue => values.password === repeatValue
    )
  }).validateSync(values, { abortEarly: false });

const FullWidthButton = styled(Button)`
  width: 100%;
`;

type SetupFormInnerProps = { onFinish: () => void };

const SetupFormInner: React.FC<SetupFormInnerProps & FormComponentProps> = ({
  form: { getFieldDecorator, validateFields, setFields },
  onFinish
}) => {
  const [{ loading, error, response }, setupSystem] = useAxios({
    url: "/api/admin/system-config/setup",
    method: "POST",
    manual: true
  });
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        try {
          validate(values);
          setupSystem({
            data: {
              setupPassword: values.setupPassword,
              bookingEmail: values.bookingEmail,
              password: values.password
            }
          });
        } catch (e) {
          setFields(
            e.inner.reduce(
              (acc: any, val: any) => ({
                ...acc,
                [val.path]: {
                  value: values[val.path],
                  errors: [new Error(val.errors[0])]
                }
              }),
              {}
            )
          );
        }
      }
    });
  };
  if (response && response.status === 200) {
    return (
      <>
        <h3>Sie haben das System erfolgreich konfiguriert.</h3>
        <Button onClick={onFinish}>Weiter zum Login</Button>
      </>
    );
  }
  return (
    <Form onSubmit={handleSubmit}>
      <h2>Setup der Anwendung</h2>
      <p>
        Bevor Sie den Adminbereich nutzen können, müssen noch ein paar
        Kleinigkeiten erledigt werden. Bitte geben Sie das Setup-Kennwort ein
        und die weiteren Daten, um das System zu konfigurieren.
      </p>
      <Form.Item>
        {getFieldDecorator("setupPassword", {
          rules: [
            {
              required: true,
              message: "Bitte geben Sie das Setup-Kennwort ein."
            }
          ]
        })(
          <Input
            type="password"
            placeholder="Setup-Kennwort"
            addonAfter={
              <Tooltip title="Dieses Kennwort wurde Ihnen bei Auslieferung der Software mitgeteilt. Es authorisiert Sie für das Setup des Systems.">
                <Icon type="info-circle" />
              </Tooltip>
            }
          />
        )}
      </Form.Item>
      <h3>Definieren Sie die Buchungs-Email:</h3>
      <Form.Item>
        {getFieldDecorator("bookingEmail", {
          rules: [
            {
              required: true,
              message: "Bitte geben Sie eine Email Adresse an."
            }
          ]
        })(
          <Input
            prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Buchungs-Email"
            addonAfter={
              <Tooltip title="An diese Email Adresse werden alle Buchungsanfragen von Kunden geschickt.">
                <Icon type="info-circle" />
              </Tooltip>
            }
          />
        )}
      </Form.Item>
      <h3>Vergeben Sie ein Passwort:</h3>
      <Form.Item>
        {getFieldDecorator("password", {
          rules: [
            {
              required: true,
              message: "Bitte vergeben Sie ein Kennwort."
            }
          ]
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder="Vergeben Sie ein Passwort."
            addonAfter={
              <Tooltip title="Dieses Passwort wird Ihr Zugang zum Administrationsbereich sein. Bitte notieren Sie es sich gut.">
                <Icon type="info-circle" />
              </Tooltip>
            }
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator("passwordRepeat", {
          rules: [
            {
              required: true,
              message: "Bitte geben Sie das Kennwort erneut ein."
            }
          ]
        })(
          <Input
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder="Wiederholen Sie Ihr Passwort."
          />
        )}
      </Form.Item>
      <Form.Item>
        {error && error.response && error.response.data && (
          <Alert
            style={{ marginBottom: "1rem" }}
            type="error"
            message="Ein Fehler ist aufgetreten."
            description={error.response.data}
          />
        )}
        <FullWidthButton type="primary" htmlType="submit" loading={loading}>
          System konfigurieren
        </FullWidthButton>
      </Form.Item>
    </Form>
  );
};
export const SetupForm = Form.create<
  SetupFormInnerProps & FormComponentProps
>()(SetupFormInner);
