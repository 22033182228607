import * as React from "react";
import { Icon, notification, Spin } from "antd";
import { useAxios } from "../../../../util/useAxios";
import { MoveDirection } from "@accommod/common";

export const MoveRoomType: React.FC<{
  itemCount: number;
  index: number;
  orderNumber: number;
  direction: MoveDirection;
  reFetch: () => void;
}> = ({ index, itemCount, orderNumber, reFetch, direction }) => {
  const onError = () => {
    notification.error({
      message: "Beim Verschieben des Zimmers ist ein Fehler aufgetreten"
    });
  };
  const onSuccess = () => reFetch();

  const [{ loading }, request] = useAxios({
    method: "POST",
    manual: true,
    onError,
    onSuccess,
    url: "/api/admin/room-types/move",
    data: {
      direction,
      orderNumber
    }
  });

  if (loading) {
    return <Spin />;
  }
  if (direction === MoveDirection.Up && index > 0) {
    return (
      <Icon
        type="arrow-left"
        title="Zimmer nach vorne verschieben."
        onClick={() => request()}
      />
    );
  }

  if (direction === MoveDirection.Down && index < itemCount - 1) {
    return (
      <Icon
        type="arrow-right"
        title="Zimmer nach hinten verschieben."
        onClick={() => request()}
      />
    );
  }
  return null;
};
