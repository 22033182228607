import * as React from "react";
import { Field, FieldProps, FormikProps } from "formik";
import { Input, InputNumber, Switch } from "formik-antd";
import { ArrangementImageUpload } from "./ArrangementImageUpload";
import { ArrangementPDFUpload } from "./ArrangementPDFUpload";
import moment from "moment";
import * as Yup from "yup";
import { FormItem, InputGroup } from "../../../components/FormItem";
import { Button, Col, DatePicker, Icon, Row, Tooltip } from "antd";

const { RangePicker } = DatePicker;
const { TextArea } = Input;
export type FormArrangement = {
  title: string | undefined;
  text: string | undefined;
  isActive: boolean;
  imageFileName: string | undefined;
  pdfFileName: string | undefined;
  priceEZ: number | undefined;
  priceDZ: number | undefined;
  nights: number | undefined;
  timeFrame: [moment.Moment, moment.Moment] | undefined;
};
type ArrangementFormProps = {
  formik: FormikProps<FormArrangement>;
};
export const arrangementValidationSchema = Yup.object({
  title: Yup.string()
    .required("Bitte geben Sie einen Titel ein.")
    .max(255, "Hier sind maximal ${max} Zeichen erlaubt."),
  text: Yup.string()
    .required("Bitte geben Sie eine Beschreibung ein.")
    .max(5000, "Hier sind maximal ${max} Zeichen erlaubt."),
  priceEZ: Yup.number().required(
    "Bitte geben Sie einen Preis für Einzelzimmer p.P. an."
  ),
  priceDZ: Yup.number().required(
    "Bitte geben Sie einen Preis für Doppelzimmer p.P. an."
  ),
  nights: Yup.number().required("Bitte geben Sie die Anzahl an Nächten an."),
  imageFileName: Yup.string().required("Bitte laden Sie ein Bild hoch."),
  pdfFileName: Yup.string().required("Bitte laden Sie ein PDF hoch."),
  timeFrame: Yup.array()
    .required("Bitte wählen Sie Start- und Enddatum aus.")
    .test(
      "timeframe-is-set",
      "Bitte wählen Sie Start- und Enddatum aus.",
      timeFrame => Array.isArray(timeFrame) && timeFrame[0] && timeFrame[1]
    )
    .test(
      "start-before-end",
      "Das Startdatum muss vor dem Enddatum liegen.",
      timeFrame => Array.isArray(timeFrame) && timeFrame[0] < timeFrame[1]
    )
});
export const ArrangementForm: React.FC<ArrangementFormProps> = () => (
  <>
    <Row gutter={32}>
      <Col lg={{ span: 12 }} sm={{ span: 24 }}>
        <FormItem label="Überschrift" name="title">
          <Input name="title" placeholder="Überschrift" autoFocus />
        </FormItem>
        <FormItem label="Beschreibungs-Text" name="text">
          <TextArea
            name="text"
            autoSize={{ minRows: 5, maxRows: 14 }}
            placeholder="Beschreibungs-Text"
          />
        </FormItem>
      </Col>
      <Col lg={{ span: 12 }} sm={{ span: 24 }}>
        <FormItem label="Preis Einzelzimmer p.P." name="priceEZ">
          <InputGroup compact>
            <InputNumber
              name="priceEZ"
              style={{ width: 240, maxWidth: "100%" }}
              placeholder="Preis Einzelzimmer"
              min={0}
              precision={2}
              decimalSeparator=","
            />
            <Button disabled>€</Button>
          </InputGroup>
        </FormItem>
        <FormItem label="Preis Doppelzimmer p.P." name="priceDZ">
          <InputGroup compact>
            <InputNumber
              name="priceDZ"
              style={{ width: 240, maxWidth: "100%" }}
              placeholder="Preis Doppelzimmer"
              min={0}
              precision={2}
              decimalSeparator=","
            />
            <Button disabled>€</Button>
          </InputGroup>
        </FormItem>
        <FormItem label="Anzahl Nächte" name="nights">
          <InputNumber
            style={{ width: 240, maxWidth: "100%" }}
            name="nights"
            placeholder="Anzahl Nächte"
            min={0}
          />
        </FormItem>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <FormItem label="Aktiv" name="isActive">
          <Switch
            name="isActive"
            checkedChildren="Aktiv"
            unCheckedChildren="Inaktiv"
          />
          <Tooltip title="falls ja: direkt auf Website sichtbar nach dem Speichern">
            {" "}
            <Icon type="info-circle" />
          </Tooltip>
        </FormItem>
        <Field name="timeFrame">
          {({ field: { onChange, value } }: FieldProps) => (
            <FormItem label="Gültigkeis-Zeitraum:" name="timeFrame">
              <RangePicker
                name="timeFrame"
                style={{ width: "100%" }}
                value={value}
                onChange={([date1, date2]) => {
                  if (date1) {
                    date1.seconds(0);
                  }
                  if (date2) {
                    date2.seconds(0);
                  }
                  onChange({
                    target: { name: "timeFrame", value: [date1, date2] }
                  });
                }}
                showTime={{
                  format: "HH:mm",
                  defaultValue: moment("08:00:00", "HH:mm:ss")
                }}
              />
            </FormItem>
          )}
        </Field>
      </Col>

      <Col lg={{ span: 12 }} sm={{ span: 24 }}>
        <Field name="imageFileName">
          {({ field: { onChange, value } }: FieldProps) => {
            return (
              <FormItem label="Bild" name="imageFileName">
                <ArrangementImageUpload
                  value={value}
                  onChange={value =>
                    onChange({
                      target: { name: "imageFileName", value }
                    })
                  }
                />
              </FormItem>
            );
          }}
        </Field>
      </Col>
      <Col lg={{ span: 12 }} sm={{ span: 24 }}>
        <Field name="pdfFileName">
          {({ field: { onChange, value } }: FieldProps) => {
            return (
              <FormItem label="PDF mit Details" name="pdfFileName">
                <ArrangementPDFUpload
                  value={value}
                  onChange={value =>
                    onChange({
                      target: { name: "pdfFileName", value }
                    })
                  }
                />
              </FormItem>
            );
          }}
        </Field>
      </Col>
    </Row>
  </>
);
