"use strict";

var _defineProperty = require("/var/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _exports$BookableName, _exports$cRoomRateNam;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var types_1 = require("./types");

var MoveDirection;

(function (MoveDirection) {
  MoveDirection["Up"] = "up";
  MoveDirection["Down"] = "down";
})(MoveDirection = exports.MoveDirection || (exports.MoveDirection = {}));

var MovePosition;

(function (MovePosition) {
  MovePosition["After"] = "after";
  MovePosition["Before"] = "before";
})(MovePosition = exports.MovePosition || (exports.MovePosition = {}));

exports.BookableNameMap = (_exports$BookableName = {}, _defineProperty(_exports$BookableName, types_1.Bookable.Arrangement, "Arrangement"), _defineProperty(_exports$BookableName, types_1.Bookable.ConferenceRoom, "Tagungsraum"), _defineProperty(_exports$BookableName, types_1.Bookable.Room, "Zimmer"), _exports$BookableName);
exports.cRoomRateNames = (_exports$cRoomRateNam = {}, _defineProperty(_exports$cRoomRateNam, types_1.ConferenceBookingRate.HalfTime, "Halbtagespauschale"), _defineProperty(_exports$cRoomRateNam, types_1.ConferenceBookingRate.FullTime, "Tagespauschale"), _exports$cRoomRateNam);