import React from "react";
import { Button, Divider, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { IArrangement } from "@accommod/common";
import { DeleteArrangement } from "./DeleteArrangement";
import { EditArrangement } from "./EditArrangement";
import moment from "moment";
import { LongTextCover } from "../../components/LongTextCover";
import { SwitchActivation } from "./components/SwitchActivation";
import { ImagePreviewModal } from "../../components/ImagePreviewModal";
import { MoveArrangement } from "./MoveArrangement";
import styled from "styled-components";

const CellContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > b {
    font-size: 2rem;
  }
`;

const isActive = (arrangement: IArrangement) =>
  moment().isAfter(moment(arrangement.timeFrameStart)) &&
  moment().isBefore(moment(arrangement.timeFrameEnd));

type ArrangementsTableProps = {
  data: IArrangement[];
  reFetch: () => void;
  loading: boolean;
};
const makeColumns: (props: {
  reFetch: () => void;
  arrangementCount: number;
}) => ColumnProps<IArrangement>[] = ({ reFetch, arrangementCount }) => [
  {
    title: "Position",
    width: "300px",
    dataIndex: "orderNumber",
    key: "orderNumber",
    render: (orderNumber, arrangement, index) => {
      return (
        <CellContainer>
          <b>#{index + 1}</b>
          {index !== 0 && (
            <>
              <Divider
                style={{ background: "#333", fontSize: "1.6rem" }}
                type="vertical"
              />
              <MoveArrangement
                direction="up"
                orderNumber={orderNumber}
                reFetch={reFetch}
              />
            </>
          )}
          {index !== arrangementCount - 1 && (
            <>
              <Divider
                style={{ background: "#333", fontSize: "1.6rem" }}
                type="vertical"
              />
              <MoveArrangement
                direction="down"
                orderNumber={orderNumber}
                reFetch={reFetch}
              />
            </>
          )}
          <Divider
            style={{ background: "#333", fontSize: "1.6rem" }}
            type="vertical"
          />
          <SwitchActivation onSwitch={reFetch} arrangement={arrangement} />
          <Divider
            style={{ background: "#333", fontSize: "1.6rem" }}
            type="vertical"
          />
          <EditArrangement onEdit={reFetch} arrangement={arrangement} />
          <Divider
            style={{ background: "#333", fontSize: "1.6rem" }}
            type="vertical"
          />
          <DeleteArrangement onDelete={reFetch} arrangement={arrangement} />
        </CellContainer>
      );
    }
  },
  {
    title: "Gültigkeits-Zeitraum",
    dataIndex: "timeFrameStart",
    width: 200,
    key: "timeFrame",
    render: (_, arrangement) => {
      const timeFrameActive = isActive(arrangement);
      return (
        <div
          title={
            !timeFrameActive
              ? "Dieses Arrangement ist momentan nicht buchbar."
              : undefined
          }
          style={
            !timeFrameActive
              ? {
                  background: "#ea4e4e",
                  padding: "0.5rem",
                  borderRadius: "0.5rem"
                }
              : {}
          }
        >
          {moment(arrangement.timeFrameStart).format("DD.MM.YYYY - HH:mm")}
          <br />
          BIS
          <br />
          {moment(arrangement.timeFrameEnd).format("DD.MM.YYYY - HH:mm")}
        </div>
      );
    }
  },
  {
    title: "Überschrift",
    dataIndex: "title",
    key: "title"
  },
  {
    title: "Text",
    dataIndex: "text",
    key: "text",
    render: text => <LongTextCover>{text}</LongTextCover>
  },
  {
    title: "Preis EZ",
    dataIndex: "priceEZ",
    key: "priceEZ",
    render: price => `${price}€`
  },
  {
    title: "Preis DZ",
    dataIndex: "priceDZ",
    key: "priceDZ",
    render: price => `${price}€`
  },
  {
    title: "Nächte",
    dataIndex: "nights",
    key: "nights",
    render: nights => `${nights} Nächte`
  },
  {
    title: "Bild",
    dataIndex: "imageFileName",
    key: "imageFileName",
    render: fileName => (
      <ImagePreviewModal
        imgSrc={`/e-static/arrangement-images/${fileName}`}
        alt="Arrangement Bild"
      />
    )
  },
  {
    title: "PDF",
    dataIndex: "pdfFileName",
    key: "pdfFileName",
    render: fileName => (
      <a
        href={`/e-static/arrangement-pdfs/${fileName}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <Button>PDF öffnen</Button>
      </a>
    )
  }
];

export const ArrangementsTable: React.FC<ArrangementsTableProps> = ({
  loading,
  reFetch,
  data
}) => {
  const arrangementCount = data && data.length ? data.length : 0;
  return (
    <Table
      scroll={{ x: 1500 }}
      pagination={false}
      rowKey="id"
      columns={makeColumns({ reFetch, arrangementCount })}
      loading={loading}
      dataSource={!loading ? data : []}
    />
  );
};
